import { documentComponentTypes } from '@digital-revisor/node-report-editor-model';
import reportEditor from 'http/reportEditor';

const getMarginAsStyleProps = ([left, top, right, bottom] = []) => {
    return {
        marginLeft:   (left   || 0) + 'em',
        marginTop:    (top    || 0) + 'em',
        marginRight:  (right  || 0) + 'em',
        marginBottom: (bottom || 0) + 'em',
    };
};

const getPaddingAsStyleProps = ([left, top, right, bottom] = []) => {
    return {
        paddingLeft:   (left   || 0) + 'em',
        paddingTop:    (top    || 0) + 'em',
        paddingRight:  (right  || 0) + 'em',
        paddingBottom: (bottom || 0) + 'em',
    };
};

const makeHTMLElement = (tag, { children, style, ...other } = {}) => {
    const out = {
        tag,
        style: {},
        children: [],
    };

    Object.entries(style || {}).forEach(([key, val]) => {
        out.style[key] = val;
    })

    Object.entries(other).forEach(([key, val]) => {
        out[key] = val;
    });

    if (children) {
        if (!Array.isArray(children)) {
            children = [children];
        }

        for (let child of children) {
            child && out.children.push(child);
        }
    }

    return out;
};

const convertSection = ({ data }) => {
    if (data.hidden) {
        return makeHTMLElement('div', {
            style: {
                fontStyle: 'italic',
            },
            innerText: 'Sektionen er skjult og vil ikke indgå i den endelige rapport',
        });
    }

    const convertedChilden = data.children.map(element => convertDocumentComponentToHTML(element));

    const mainContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: data.contentGap ? `${data.contentGap}em` : undefined,
    };

    const backgroundColor = data.backgroundColor;

    if (data.width > 0) {
        return makeHTMLElement('div', {
            style: { display: 'flex', justifyContent: 'center', backgroundColor },
            children: makeHTMLElement('div', {
                style: { width: data.width + 'em', ...mainContainerStyle },
                children: convertedChilden,
            }),
        });
    }

    return makeHTMLElement('div', {
        style: { backgroundColor, ...mainContainerStyle },
        children: convertedChilden,
    });
};

const convertImage = ({ data }) => {
    if (!data.imageData) {
        return makeHTMLElement('div');
    }
    const height = data.height ? `${data.height}px` : '150px';
    return makeHTMLElement('img', {
        src: data.imageData,
        style: {
            maxHeight: height,
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
        }
    });
};

const convertContainer = ({ data }) => {
    let backgroundImage;
    let backgroundSize;
    let backgroundPosition;
    if (data.backgroundImage) {
        backgroundImage = `url("${reportEditor.getAssetURL(data.backgroundImage)}")`;
        backgroundSize = 'cover';
        backgroundPosition = 'center';
    }

    // add a slight blur if background is transparent
    let backdropFilter;
    if (data.backgroundColor && data.backgroundColor.startsWith('rgba')) {
        backdropFilter = 'blur(5px)';
    }

    let boxShadow;
    if (data.shadow) {
        boxShadow = 'rgba(0, 0, 0, 0.15) 0px 2px 10px';
    }

    return makeHTMLElement('div', {
        style: {
            display: 'flex',
            flexDirection: data.contentDirection,
            alignItems: data.horizontalContentAlignment,
            justifyContent: data.verticalContentAlignment,
            color: data.textColor,
            fontWeight: data.bold ? 'bold' : 'inherit', 
            fontSize: data.fontSize || 12,
            textAlign: data.textAlign,
            backgroundColor: data.backgroundColor,
            backdropFilter,
            boxShadow,
            backgroundImage,
            backgroundSize,
            backgroundPosition,
            borderRadius: data.borderRadius,
            gap: data.contentGap ? `${data.contentGap}em` : undefined,
            ...getMarginAsStyleProps(data.margin),
            ...getPaddingAsStyleProps(data.padding),
        },
        children: data.children.map(childDocumentComponent => convertDocumentComponentToHTML(childDocumentComponent)),
    });
};

const convertText = ({ data }) => {
    const rawText = (
        typeof data.text === 'object'
            ? data.text?.text
            : data.text
    );

    const byNewline = rawText?.split('\n') || [];
    const partitionedText = byNewline.map(line => {
        if (line) {
            return makeHTMLElement('div', {
                innerText: line,
            });
        } else {
            return makeHTMLElement('div', {
                children: [makeHTMLElement('br')],
            });
        }
    });

    return makeHTMLElement('div', {
        children: partitionedText,
        style: {
            color: data.textColor,
            fontWeight: data.bold ? 'bold' : 'inherit', 
            fontSize: data.fontSize || 12,
            textAlign: data.textAlign,
            flexDirection: 'column',
            display: 'flex',
            ...getMarginAsStyleProps(data.margin),
        },
    });
};

const convertDivider = ({ data }) => {
    return makeHTMLElement('div', {
        style: {
            ...getMarginAsStyleProps(data.margin),
            width: '100%',
        },
        children: makeHTMLElement('div', {
            style: {
                height: '1.5px',
                background: data.color,
                opacity: 0.25,
            },
        }),
    });
};

const convertGridAsTable = ({ data }) => {
    const { headers, rows } = data.tableData;

    const columnNames = headers.map(({ header }) => header);

    const tableRows = rows.map(row => {
        return row.columns.map(column => column.text);
    });
    
    const borderColor = '1px solid rgba(0, 0, 0, 0.2)';
    const borderRadiusAmount = '0.5em';

    const cellStyle = {
        borderBottom: borderColor,
        borderRight: borderColor,
        padding: '1em 1.5em',
    };

    const table = makeHTMLElement('table', {
        style: {
            width: '100%',
            borderCollapse: 'seperate',
            borderSpacing: 0,
            fontSize: '14px'
        },
        children: [
            // header
            makeHTMLElement('thead', {
                style: { backgroundColor: 'rgba(0, 0, 0, 0.05)', textAlign: 'left' },
                children: [
                    makeHTMLElement('tr', {
                        children: columnNames.map((columnName, idx) => {
                            const isFirst = idx === 0;
                            const isLast = idx === columnNames.length - 1;

                            return makeHTMLElement('th', {
                                style: {
                                    ...cellStyle,
                                    borderTop: borderColor,
                                    borderLeft: isFirst ? borderColor : undefined,
                                    borderTopLeftRadius: isFirst ? borderRadiusAmount : undefined,
                                    borderTopRightRadius: isLast ? borderRadiusAmount : undefined,
                                },
                                innerText: columnName,
                            });
                        })
                    }),
                ],
            }),

            // body
            ...tableRows.map((row, rowIdx) => {
                return makeHTMLElement('tr', {
                    children: row.map((column, columnIdx) => {
                        const isFirstColumn = columnIdx === 0;
                        const isLastColumn = columnIdx === columnNames.length - 1;
                        const isLastRow = rowIdx === tableRows.length - 1;
                        return makeHTMLElement('td', {
                            style: {
                                ...cellStyle,
                                borderLeft: isFirstColumn ? borderColor : undefined,
                                borderBottomLeftRadius: isLastRow && isFirstColumn ? borderRadiusAmount : undefined,
                                borderBottomRightRadius: isLastRow && isLastColumn ? borderRadiusAmount : undefined,
                            },
                            innerText: column,
                        });
                    }),
                });
            }),
        ],
    });

    return table;
};

const convertGrid = ({ data, ...other }) => {
    if (data.isTable) {
        return convertGridAsTable({ data, ...other });
    }

    const widths = data.widths || [];
    if (widths.length === 0) {
        const widthPct = 100 / data.columnsPerRow;
        while (widths.length < data.columnsPerRow) {
            widths.push(widthPct + '%');
        }
    }

    const gridTemplateCSS = widths.map(width => `minmax(auto, ${width})`).join(' ');

    return makeHTMLElement('div', {
        style: {
            display: 'grid',
            width: '100%',
            gridTemplateColumns: gridTemplateCSS,
            gap: data.contentGap ? `${data.contentGap}em` : undefined,
        },
        children: data.columns.map(convertDocumentComponentToHTML),
    });
};

const convertBarChart = chartData => {
    const biggestValue = Math.max(...chartData.map(data => data.value));

    const theLayout = [];

    for (const data of chartData) {
        const percentage = data.value / biggestValue * 100;

        const bar = makeHTMLElement('div', {
            style: {
                height: '2em',
                backgroundColor: data.color,
                borderRadius: '.5em',
                width: `${percentage}%`,
            },
        });

        const text = makeHTMLElement('div', {
            style: {
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                marginLeft: data.label ? '1em' : '0',
            },
            innerText: data.label,
        });

        theLayout.push(bar);
        theLayout.push(text);
    }

    return makeHTMLElement('div', {
        style: {
            display: 'grid',
            gridTemplateColumns: '1fr max-content',
            width: '100%',
            rowGap: '1em',
        },
        children: theLayout,
    });
};

const convertStackedBarChart = chartData => {
    const sumOfValues = chartData.reduce((acc, { value }) => acc += value, 0);

    const formattedData = chartData.map(({ label, color, value }) => {
        const percentageOfSum = value / sumOfValues * 100;
        return {
            label,
            value,
            percentageOfSum,
            color: color,
        };
    });

    const gridLayoutCSS = formattedData.map(data => {
        return `minmax(auto, ${data.percentageOfSum}%)`;
    }).join(' ');

    const dataVisualizationGrid = makeHTMLElement('div', {
        style: {
            display: 'grid',
            gridTemplateColumns: gridLayoutCSS,
            width: '100%',
        },
        children: formattedData.map((data, idx) => {
            const isFirst = idx === 0;
            const isLast = idx === formattedData.length - 1;

            const borderRadiusAmount = '0.5em';

            let borderBottomLeftRadius;
            let borderTopLeftRadius;
            if (isFirst) {
                borderBottomLeftRadius = borderRadiusAmount;
                borderTopLeftRadius = borderRadiusAmount;
            }

            let borderBottomRightRadius;
            let borderTopRightRadius;
            if (isLast) {
                borderBottomRightRadius = borderRadiusAmount;
                borderTopRightRadius = borderRadiusAmount;
            }

            return makeHTMLElement('div', {
                style: {
                    height: '4em',
                    backgroundColor: data.color,
                    borderBottomLeftRadius,
                    borderTopLeftRadius,
                    borderBottomRightRadius,
                    borderTopRightRadius,
                },
            });
        }),
    });

    return makeHTMLElement('div', {
        style: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            width: '100%',
        },
        children: [
            dataVisualizationGrid,
        ],
    });
};

const convertGaugeChart = chartData => {
    let labelContainer;
    if (chartData.label) {
        labelContainer = makeHTMLElement('div', {
            style: {
                position: 'absolute',
                width: '100%',
                height: '100%',
            },
            children: [
                makeHTMLElement('div', {
                    style: {
                        display: 'flex',
                        alignItems: 'end', 
                        justifyContent: 'center', 
                        height: '88%',
                        fontWeight: 'bold',
                    },
                    children: [
                        makeHTMLElement('div', { innerText: chartData.label })
                    ],
                }),
            ],
        });
    }

    return makeHTMLElement('div', {
        children: [
            makeHTMLElement('div', {
                style: {
                    position: 'relative',
                },
                children: [
                    ...(labelContainer ? [labelContainer] : []),
                    makeHTMLElement('img', {
                        style: {
                            width: '100%',
                        },
                        src: 'data:image/svg+xml;base64,' + window.btoa(chartData.svg)
                    }),
                ],
            })
        ],
    });
};

const convertChart = ({ data }) => {
    switch (data.kind) {
        case 'bar': {
            return convertBarChart(data.chartData);
        }

        case 'stackedBar': {
            return convertStackedBarChart(data.chartData);
        }

        case 'gauge': {
            return convertGaugeChart(data.chartData);
        }

        default: {
            return null;
        }
    }
};

const convertChartLabel = ({ data }) => {
    return makeHTMLElement('div', {
        style: {
            display: 'flex',
            fontWeight: 'bold',
            gap: '0.5em',
        },
        children: [
            makeHTMLElement('div', {
                style: {
                    backgroundColor: data.color,
                    display: 'inline-block',
                    width: '1.25em',
                    height: '1.25em',
                    borderRadius: '0.25em',
                },
            }),
            makeHTMLElement('div', { innerText: data.label })
        ],
    });
};

const convertDynamicColumnDivider = ({ data }) => {
    const convertedChilden = data.children.map(element => {
        const convertedChild = convertDocumentComponentToHTML(element);

        return makeHTMLElement('div', {
            style: {
                breakInside: 'avoid',
                borderBottom: `${data.rowGap}em solid transparent`,
            },
            children: [convertedChild],
        });
    });

    return makeHTMLElement('div', {
        style: {
            columns: 2,
            columnGap: `${data.columnGap}em`,
            paddingTop: `${data.rowGap}em`,
            paddingRight: `${data.rowGap}em`,
            paddingLeft: `${data.rowGap}em`,
            width: '100%',
        },
        children: convertedChilden,
    });
};

const convertDocumentComponentToHTML = (documentComponent) => {
    if (!documentComponent) {
        return;
    }

    const documentComponentToElementConverter = {
        [documentComponentTypes.section.id]:              convertSection,
        [documentComponentTypes.image.id]:                convertImage,
        [documentComponentTypes.container.id]:            convertContainer,
        [documentComponentTypes.text.id]:                 convertText,
        [documentComponentTypes.divider.id]:              convertDivider,
        [documentComponentTypes.grid.id]:                 convertGrid,
        [documentComponentTypes.chart.id]:                convertChart,
        [documentComponentTypes.chartLabel.id]:           convertChartLabel,
        [documentComponentTypes.dynamicColumnDivider.id]: convertDynamicColumnDivider,
    }[documentComponent.kind];

    if (!documentComponentToElementConverter) {
        return;
    }

    return {
        ...documentComponentToElementConverter(documentComponent),
        sourceDocumentComponent: documentComponent,
    };
};

const createMarkdownFromSection = (section) => {
    return makeHTMLElement('div', {
        children: makeHTMLElement('div', {
            children: convertDocumentComponentToHTML(section),
        }),
    });
};

export default createMarkdownFromSection;